import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { environment } from '@environments/environment';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, interval } from 'rxjs';
import { AnalyticsTrackingFacadeService } from './analytics-tracking-facade.service';
import { ApplicationInsightsService } from './application-insights.service';
import { CacheService } from './cache.service';
import { GlobalVarService } from './global-var.service';
import { IntervalService } from './interval.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {
	private errorTimerSubscription: Subscription;
	private ignoreTimerSubscription: Subscription;
	private isErrorTimerRunning: boolean;
	private isIgnoreTimerRunning: boolean;
	private errorCount = 0;
	private initialized = false;
	private static errorMsgs: string[] = [];

	constructor(
		private cacheService: CacheService,
		private globalVarService: GlobalVarService,
		private deviceDetectorService: DeviceDetectorService,
		private aiService: ApplicationInsightsService,
		private router: Router,
		private analyticsTrackingFacadeService: AnalyticsTrackingFacadeService,
		private intervalService: IntervalService
	) { super(); }

	async handleError(error: Error, useRefferer?: boolean) {
		if (this.isInCrashloopBackoff()) { return; }

		// cache leeren und neu laden wenn navigiert wird aber die chunks nicht vorhanden sind
		const reloadMgsParts = ['ChunkLoadError', 'SyntaxError: Unexpected token', 'SyntaxError: JSON Parse error'];
		if (reloadMgsParts.some(msgPart => error.message.includes(msgPart))) {
			await this.cacheService.clearCacheAndReload();
			return;
		}



		if (error.message.includes('AADB2C90091')) {
			// dieser hack ist notwendig weil der msalguard bei click auf abbrechen und zurücknavigieren dann den Zugriff verweigert trotz Rechte
			if (!isNullish(this.globalVarService.getUserEdit())) {
				location.href = this.globalVarService.getUserEdit()
				return;
			}
			return;
		}
		// Abfrage auf Funktionen welche alte Browser nicht unterstützen. Altbrowser werden somit auch ausgeschlossen
		if (!('IntersectionObserver' in window) || !('ResizeObserver' in window) || !(Array.prototype.flatMap)) {
			return;
		}
		// Zu "No token request found in cache":
		// Logout auf Startseite und Tabübergreiffenden Verhalten funktioniert jetzt(kein Logout Hash mehr und auch keine Request die auf einen Angemeldeten Benutzer zielen werden mehr abgefragt)
		// Allerdings wird NUR AUF DER Startseite beim Logout ein Fehler geworfen mit 'No token request found in cache', Nutzer verhält sich jedoch als ausgeloggter Nutzer ohne weitere Nebenwirkungen
		// TODO: Prüfen ob der Fehler in Zukunft noch geworfen wird
		const ignoresdMgsParts = ['AADB2C90118', 'ResizeObserver', 'No token request found in cache', 'SyntaxError: Unexpected token'];

		if (ignoresdMgsParts.some(msgPart => error.message.includes(msgPart))) {
			return;
		}

		let stack: string[]
		// Abdeckung von allen Chromiumbrowsern
		if ((window as any).chrome) {
			stack = error.stack?.split('\n    at ');
		}

		if (this.deviceDetectorService.browser === "Firefox" || this.deviceDetectorService.browser === "Safari") {
			stack = error.stack?.split('@');
		}
		// aussortieren von polyfills ccm19 forge
		const ignoresdFileParts = ['polyfills', environment.ccm19Url, 'developer.api.autodesk.com'];
		// Firefox soll in Non Production auch die Polyfills anzeigen
		if (!environment.production && this.deviceDetectorService.browser === "Firefox") {
			ignoresdFileParts.shift()
		}
		if (ignoresdFileParts.some(filePart => stack?.[1]?.includes(filePart))) {
			return;
		}

		// Doppelte Fehler nicht mehr senden
		if (ErrorHandlerService.errorMsgs.some(msg => msg === error.message)) {
			return;
		}
		ErrorHandlerService.errorMsgs.push(error.message);
		console.error(error);
		if (this.initialized) {
			const navigation = useRefferer ? this.analyticsTrackingFacadeService.getReferrer() : this.router.url
			this.aiService.trackException(error, navigation, SeverityLevel.Error);
		}
	}

	public setInitialized() {
		this.initialized = true;
	}

	private isInCrashloopBackoff(): boolean {
		if (this.isIgnoreTimerRunning) { return true }

		if (this.isErrorTimerRunning === false) {
			this.errorTimerSubscription = interval(1000).subscribe(() => { this.isErrorTimerRunning = false; this.errorTimerSubscription.unsubscribe() })
		}

		this.errorCount++;

		if (this.errorCount > 10) {
			console.log("Application is in Crashloop Backoff. An error has been thrown, which keeps on reoccuring. All errors will be ignored for 1 second now to avoid a UI freeze.")
			this.errorCount = 0
			this.isIgnoreTimerRunning = true
			this.ignoreTimerSubscription = interval(1000).subscribe(() => { this.isIgnoreTimerRunning = false; this.ignoreTimerSubscription.unsubscribe() })
			return true
		}

		return false
	}


	public insertDefaultList(errorEventList: ErrorEvent[], errorEventFunction: (ev: ErrorEvent) => number) {
		const intervalSubscription = this.intervalService.getInterval().subscribe(async () => {
			if (this.initialized && !isNullish(this.aiService.hasInsights())) {
				intervalSubscription.unsubscribe()
				window.removeEventListener('error', errorEventFunction);
				errorEventList.forEach(async errorEvent => {
					const error: Error = isNullish(errorEvent.error) ? { message: errorEvent.message, name: errorEvent.message } : errorEvent.error
					await this.handleError(error)
				})
				if (this.globalVarService.getLoginError()) {
					await this.handleError(new Error("Could not initialize InterfaceType from local storage on Track Login Success Event."), true);
					this.globalVarService.deleteLoginError();
				}

			}
		})
	}
}
