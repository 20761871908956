import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-menu-button-img',
    templateUrl: './user-menu-button-img.component.html',
    styleUrls: ['./user-menu-button-img.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class UserMenuButtonImgComponent implements OnInit {
	@Input() inputTranslate: string
	@Input() hover = true;

	constructor() { }

	ngOnInit(): void {
	}

}
