import { Injectable, OnDestroy } from '@angular/core';
import { InterfaceType, LoginSource } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { Subscription } from 'rxjs';
import { AuthenticatedUserService } from './authenticated-user.service';
import { CatalogIntegrationSettingsService } from './catalog-integration-settings.service';
import { GlobalVarService } from './global-var.service';
import { ReferrerService } from './referrer.service';
import { SessionService } from './session.service';
import { SoftwareIntegrationCallbackService } from './software-integration-callback.service';
import { UrlService } from './url.service';
import { UserService } from './user.service';
import { SoftwarepartnerApiService } from './softwarepartner-api.service';

interface IApplicationState4Tracking {
	userId: string,
	sessionId: string,
	apiSessionId?: string,
	authenticatedUserId: string,
	interface: InterfaceType,
	referrer: string,
	integrationKey: string,
	applicationKey: string,
	loginRequestSource?: LoginSource,
	hostAppUserAgent: string
}




@Injectable({
	providedIn: 'root'
})
export class AnalyticsTrackingApplicationStateService implements OnDestroy {

	private integrationSettingsServiceSubscription: Subscription
	private integrationKeySubscription: Subscription
	private referrerServiceSubscription: Subscription
	private referrerBeforeEmbedRedirect: string;

	private integrationKey: string

	private isInIntegrationMode: boolean


	constructor(
		private globalVarService: GlobalVarService,

		private catalogIntegrationSettingsService: CatalogIntegrationSettingsService,
		private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService,
		private softwarepartnerApiService: SoftwarepartnerApiService,
		private referrerService: ReferrerService,
		private sessionService: SessionService,
		private userService: UserService,
		private authenticatedUserService: AuthenticatedUserService,
		private urlService: UrlService
	) {

	}

	// Called in AppModule by APP_INITIALIZER
	async init() {
		this.userService.getOrCreateUserId();
		this.sessionService.getOrCreateSessionId(true);

		// subscribe to urlService


		this.integrationSettingsServiceSubscription = this.catalogIntegrationSettingsService.isInIntegrationMode$.subscribe(isInIntegrationMode => this.isInIntegrationMode = isInIntegrationMode)
		this.integrationKeySubscription = this.catalogIntegrationSettingsService.integrationKey$.subscribe(integrationkey => {
			this.integrationKey = integrationkey
		})
		// Bei der Integration von ADE in Herstellerseiten mittels Iframe wird ein Redirect verwendet. Zum Beispiel wird die URL
		// https://www.ausschreiben.de/initintegration/v2/catalog/ade_next?integrationKey=0b591f54-0bb6-44ef-8bab-d94dc184c548 umgeleitet auf https://www.ausschreiben.de/catalog/ade_next, sofern
		// der IntegrationKey korrekt ist und folglich eine Berechtigung zur Einbettung besteht. Durch diesen internen Redirect ändert sich allerdings der document.referrer des Aufrufs,
		// der letztlich getrackt werden soll, zu www.ausschreiben.de. Da die Herstellerseite als Referrer mitgeliefert werden soll, wird vor dieser Umleitung der document.referrer
		// des Iframes - also die URL der Herstellerseite - in einen Service weggespeichert. In der nächsten Zeile wird dieser ursprüngliche referrer wieder ausgelesen.
		this.referrerServiceSubscription = this.referrerService.getAsObservable().subscribe(referrer => {
			this.referrerBeforeEmbedRedirect = referrer
		})
	}

	ngOnDestroy(): void {
		this.integrationSettingsServiceSubscription?.unsubscribe()
		this.integrationKeySubscription?.unsubscribe()
		this.referrerServiceSubscription?.unsubscribe()
	}

	getState(): IApplicationState4Tracking {
		return {
			userId: this.userService.getOrCreateUserId(),
			sessionId: this.sessionService.getOrCreateSessionId(),
			authenticatedUserId: this.authenticatedUserService.getAuthenticatedUserId(),
			interface: this.getInterfaceType(),
			referrer: this.isInIntegrationMode ? this.referrerBeforeEmbedRedirect : this.urlService.getPreviousUrl() ?? document.referrer,
			integrationKey: this.integrationKey,
			applicationKey: this.softwareIntegrationCallbackService.ApplicationKey,
			loginRequestSource: this.sessionService.getLoginSource(),
			hostAppUserAgent: this.softwareIntegrationCallbackService.HostAppUserAgent,
			apiSessionId: this.softwarepartnerApiService.hasSession() ? this.softwarepartnerApiService.getApiSessionId() : undefined
		}
	}


	private getInterfaceType() {
		if (this.isInIntegrationMode) {
			return InterfaceType.IntegrationV2
		}
		else if (this.softwareIntegrationCallbackService.hasSession() || this.globalVarService.isInSoftwareIntegrationMode()) {
			return InterfaceType.ApiV2
		}
		else if (this.softwarepartnerApiService.hasSession()) {
			return InterfaceType.ApiV3;
		}
		return InterfaceType.WebV4;
	}

}
