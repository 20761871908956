<div class="action-buttons-height">
	<app-alert [positionType]="ALERT_POSITION_TYPES.ACTION_BUTTON_TOP"></app-alert>
	@if (showActionButtons) {
	<section id="action-buttons" class="d-flex gap-2">
		<!-- Action Buttons -->
		@for (button of actionButtons; track button) {
		<lib-button ngbDropdown #dropdown="ngbDropdown" [ariaLabel]="button.ariaLabel" dropdownClass="action-button-dropdown"
			class="disabled-toggle" container="body" (openChange)="openChange($event)" [routerLinkParams]="button.routerLinkParams"
			(clicked)="action(button.type, dropdown)" [target]="button.target" variant="outline-light" [hasMinWidth]="false"
			[ngbTooltip]="button.tooltipKey | translate" [class.downloading]="button.downloading" [disabled]="button.downloading"
			[openDelay]="button.openDelay">
			@if (button.iconImg) {
			<div class="icon {{ button.iconImg }} bg-dark mask-settings"></div>
			}
			<!-- Export Dialog Fenster -->
			@if (button.type === actionButtonType.Export) {
			<div class="position-relative">
				<img src="assets/material/actionbuttons/save_alt-24px-1of2.svg" loading="lazy" alt="Export" width="24px" height="24px" />
				<img src="assets/material/actionbuttons/save_alt-24px-2of2.svg" loading="lazy" alt="Export" width="24px" height="24px"
					class="animated-export-img" />
			</div>
			<div ngbDropdownToggle role="button" aria-label="toggle export" class="no-dropdown-marker dropdown-default-position"
				dropdown-desktop-position>
				<div class="dropdown-menu" ngbDropdownMenu>
					<div [style.max-height.px]="visibleDialogPosition.height" [ngClass]="[dropdownContainerClass]"
						[style.max-width.px]="visibleDialogPosition.width" class="overflow-auto">
						@if (dropdown.isOpen()) {
						@defer {
						<app-export-dialog (setPosition)="setDialogPosition($event)" [selectedItem]="selectedItem"
							[exportSettings]="exportSettings" [catalogKey]="routingParams?.key" (closeDialog)="dropdown?.close()" />
						}
						}
					</div>
				</div>
			</div>
			}
			<!-- Positionstext in Zwischenablage und  Zusatzdatei herunterladen dropdown-->
			@if (button.type === actionButtonType.CopyMenu) {
			<div ngbDropdownToggle role="button" aria-label="toggle copyMenu"
				class="no-dropdown-marker position-relative dropdown-default-position">
				<div class="position-absolute" style="left: 0">
					<div ngbDropdownMenu aria-labelledby="dropdownBasic3">
						@if (dropdown.isOpen()) {
						@defer {
						<app-action-buttons-export [routingParams]="routingParams" [selectedItem]="selectedItem"
							[type]="actionButtonContentType.DownloadAndTextButton" />
						}
						}
					</div>
				</div>
			</div>
			}
			<!-- Positionstext in Zwischenablage kopieren-->
			@if (button.type === actionButtonType.CopyWindow && loadExportsCopy) {
			@defer {
			<app-action-buttons-export [selectedItem]="selectedItem" [routingParams]="routingParams"
				[type]="actionButtonContentType.CopyButton" />
			}
			}
			<!-- Teilen-->
			@if (button.type === actionButtonType.Share) {
			<div ngbDropdownToggle role="button" aria-label="toggle share"
				class="no-dropdown-marker position-relative dropdown-default-position">
				<div class="position-absolute" style="left: 0">
					<div ngbDropdownMenu aria-labelledby="dropdownBasic2">
						@if (copyToClipboardIsUsable) {
						<button ngbDropdownItem (click)="onCopyLink()">
							<img src="assets/material/actionbuttons/link-24px.svg" loading="lazy"
								alt="{{ 'catalog-view.detail-actions.share.copy-link' | translate }}" width="24px" height="24px" />
							{{ 'catalog-view.detail-actions.share.copy-link' | translate }}
						</button>
						}
						<a ngbDropdownItem (click)="onClickSendPerMail()" [href]="getEmailHref()">
							<img src="assets/material/actionbuttons/ic_mail_outline_24px.svg" loading="lazy"
								alt="{{ 'catalog-view.detail-actions.share.send-email.label' | translate }}" width="24px" height="24px" />
							{{ 'catalog-view.detail-actions.share.send-email.label' | translate }}
						</a>
					</div>
				</div>
			</div>
			}
		</lib-button>
		}
		<!-- Kontaktformular-->
		@if (!isWatchlistItem) {
		<lib-button [class.me-2]="currentViewType === viewType.Desktop" [hasMinWidth]="false" variant="primary"
			tooltip="{{ 'catalog-view.detail-actions.contact.tooltip' | translate }}" (clicked)="openContactForm()">
			<div style="white-space: nowrap">
				<img src="assets/material/actionbuttons/ic_mail_outline_24px.svg" loading="lazy" alt="Kontaktformular" width="24px"
					height="24px" />
				@if (currentViewType === viewType.Desktop) {
				<span class="ms-2 d-inline-block d-lg-none">{{
					'catalog-view.detail-actions.contact.label-short' | translate
					}}</span>
				}
				<span class="ms-2 d-none" [ngClass]="['d-' + contactbuttonSize + '-inline-block']">{{
					'catalog-view.detail-actions.contact.label' | translate
					}}</span>
			</div>
		</lib-button>
		}
		<!-- Übernehmen (Softwareintegration)-->
		@if (hasSoftwareIntegrationCallbackButton) {
		<lib-button (clicked)="onSoftwareIntegrationCallbackClick()" variant="primary" class="ms-auto">
			<div class="callback-button-height d-flex align-items-center">
				{{ 'catalog-view.detail-actions.software-integration-callback' | translate }}
			</div>
		</lib-button>
		}

		<!-- Übernehmenlink (Softwareintegration)-->
		@if (hasCatalogItemLinkCallbackButton) {
		<lib-button (clicked)="onCatalogItemLinkCallbackClick()" variant="primary" class="ms-auto">
			<div class="callback-button-height d-flex align-items-center">
				{{ 'catalog-view.detail-actions.catalog-item-link-callback' | translate }}
			</div>
		</lib-button>
		}

		<!-- Übernehmenlink (Sotwarepartner Api)-->
		@if (hasSoftwarepartnerApiExportButton) {
		<lib-button (clicked)="onSoftwarepartnerApiExportClick()" variant="primary" class="ms-auto">
			<div class="callback-button-height d-flex align-items-center">
				{{ 'catalog-view.detail-actions.softwarepartner-api-export' | translate }}
			</div>
		</lib-button>
		}
	</section>
	}
</div>