import { Injectable } from '@angular/core';
import { SoftwarepartnerApiSessionService } from './softwarepartner-api-session.service';
import { INode } from '@interfaces/iNode';
import { CommunicationMode, SoftwarepartnerApiSessionDto } from '../interfaces/HttpClient/AccountApiPublicModels';
import { SoftwarepartnerApiSelectionService } from './softwarepartner-api-selection.service';
import { ISoftwarepartnerApiMessage } from '../models/softwarepartnerApi/ISoftwarepartnerApiMessage';
import { SoftwarepartnerApiInteractionType } from '../models/softwarepartnerApi/SoftwarepartnerApiInteractionType';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SoftwarepartnerApiService {

  private apiSession: SoftwarepartnerApiSessionDto | null;
  private catalogItems: INode[];

  constructor(private softwarepartnerApiSessionService: SoftwarepartnerApiSessionService, private softwarepartnerApiSelectionService: SoftwarepartnerApiSelectionService, private router: Router, private route: ActivatedRoute) {
    this.softwarepartnerApiSessionService.getAsObservable().subscribe(async apiSession => {
      if (apiSession !== null && this.apiSession?.apiSessionId != apiSession.apiSessionId) {
        this.apiSession = apiSession
      }
    })
    this.softwarepartnerApiSelectionService.getAsObservable().subscribe(async catalogItems => {
      this.catalogItems = catalogItems;
    })

    if ((window as any)?.chrome?.webview) {
      (window as any).chrome.webview.addEventListener('message', (event: Event) => {
        this.handleMessage(event)
      });
    }
  }

  public handleMessage(message: any) {
    if (!this.hasSession()) { return; }

    switch (message.data) {
      case 'ade_export':
        this.postCatalogItemExport(SoftwarepartnerApiInteractionType.Button);
        break;
    }
  }

  public postCatalogItemExport(interactionType: SoftwarepartnerApiInteractionType) {
    var catalogItemGuids = this.catalogItems?.map(node => node.guid) ?? [];
    let message: ISoftwarepartnerApiMessage = {
      type: interactionType,
      catalogItemGuids
    };
    switch (this.apiSession?.communicationMode) {
      case CommunicationMode.PostMessageIFrame:
        return this.handleIframeExport(message);
      case CommunicationMode.UrlNavigation:
        return this.handleUrlNavigationExport(message);
      case CommunicationMode.PostMessageWebView:
        return this.handleWebViewExport(message);
    }
  }

  private handleIframeExport(message: ISoftwarepartnerApiMessage) {
    if (!this.apiSession?.postMessageTargetOrigin) {
      return;
    }
    if (window.top) {
      window.top.postMessage(message, this.apiSession?.postMessageTargetOrigin)
    }
  }

  private handleUrlNavigationExport(message: ISoftwarepartnerApiMessage) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { catalogItemGuids: message.catalogItemGuids.join(","), interactionType: message.type },
        queryParamsHandling: 'merge'
      }).then(() => {
        this.router.navigate([], {
          queryParams: {
            'catalogItemGuids': null,
            'interactionType': null
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  private handleWebViewExport(message: ISoftwarepartnerApiMessage) {
    if ((window as any).chrome) {
      (window as any).chrome.webview.postMessage(message)
    }
  }

  public hasSession() {
    return this.apiSession != null
  }

  public async setSessionAsync(apiSessionId: string) {
    await this.softwarepartnerApiSessionService.setAsync(apiSessionId)
  }

  public getApiSessionId() {
    return this.apiSession?.apiSessionId;
  }

  public setSelectedCatalogItems(markedNodes: INode[], currentNode: INode) {
    this.softwarepartnerApiSelectionService.setSelectedCatalogItems(markedNodes.length > 1 ? markedNodes : [currentNode]);
  }
}

